<template>
  <v-dialog
    v-model="dialogOpen"
    scrollable
    width="90%"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title>CERTIFICATI</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list two-line>
          <template>
            <v-row>
              <v-col cols="12" v-if="dialogOpen">
                <DataTableCertificati> </DataTableCertificati>
              </v-col>
            </v-row>
          </template>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog()"> CHIUDI </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataTableCertificati from "@/components/DataTableCertificati.vue";
export default {
  components: { DataTableCertificati },
  props: {
    dialogOpen: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  beforeMount() {},
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
td.text-start {
  font-size: 10px !important;
}
</style>
