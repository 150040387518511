<template>
  <v-card>
    <!-- <v-banner sticky dark style="background-color: var(--v-accent-base)">
      <v-row class="d-flex flex-row justify-center align-center">
        <v-col cols="12">
          <div>
            <span class="margin-right-8">
              <span class="secondary--text"> Nome </span>
              <em class="font-weight-regular"> {{ infoCard.name }}</em>
            </span>
            <span>
              <span class="secondary--text"> Referente </span>
              <em class="font-weight-regular"> {{ infoCard.referente }}</em>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-banner> -->
    <v-data-table
      :headers="headerTable"
      :items="records"
      :calculate-widths="false"
      class="main-table elevation-1 mt-0 headerTable"
      :loading="loading"
      loading-text="Caricamento dati... Attendere, per favore."
      multi-sort
      fixed-header
      :items-per-page="-1"
      height="calc(100vh - 450px)"
      :item-class="itemRowBackground"
    >
      <template v-slot:[`item.validTo`]="{ item }">
        <span>{{ new Date(item.validTo).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.createOn`]="{ item }">
        <span>{{ new Date(item.createOn).toLocaleString() }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
export default {
  mixins: [connectionMixin],
  props: {},
  data: () => ({
    infoCard: Object,
    loading: false,
    records: [],
    header: [],
  }),
  computed: {
    headerTable() {
      this.setHeader();
      return this.header;
    },
  },
  mounted() {
    this.infoCard = JSON.parse(localStorage.getItem("infoCard"));
    this.getData();
  },
  created() {},
  methods: {
    itemRowBackground: function (item) {
      let backGround = "";
      // compare millis
      const validTo = new Date(item.validTo).getTime();
      const today = new Date().getTime();
      const range = 2 * 30 * 24 * 60 * 60 * 1000; // 60gg
      if (validTo < today) {
        backGround = "backgroundColorError";
      } else if (validTo < today + range) {
        backGround = "backgroundColorWarning";
      }
      return backGround;
    },
    setHeader() {
      // rsDisplayLabel
      // serialNumber
      // subject
      // commonName
      // organizationUnit
      // organization
      // createOn
      // validTo
      // issuerName
      this.header = [
        {
          text: "RS Display Label",
          align: "center",
          value: "rsDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 120,
        },
        {
          text: "Serial Number",
          align: "center",
          value: "serialNumber",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 120,
        },
        {
          text: "Subject",
          align: "center",
          value: "subject",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 200,
        },
        {
          text: "Common Name",
          align: "center",
          value: "commonName",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
        },
        {
          text: "Organization Unit",
          align: "center",
          value: "organizationUnit",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 120,
        },
        {
          text: "Organization",
          align: "center",
          value: "organization",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 120,
        },
        {
          text: "Create On",
          align: "center",
          value: "createOn",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
        },
        {
          text: "Valid To",
          align: "center",
          value: "validTo",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
        },
        {
          text: "Issuer Name",
          align: "center",
          value: "issuerName",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
        },
        {
          text: "Load Balancer",
          align: "center",
          value: "loadBalancer",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
        },
      ];
    },
    async getData() {
      this.loading = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/certificates/" +
            this.infoCard.id,
          this.opts
        );
        console.log(response.data);
        this.records = response.data;
        this.loading = false;
      } catch (error) {
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.headerFont {
  font-size: 20px;
}
.v-data-table >>> tbody td {
  font-size: 12px !important;
}
.main-table table {
  table-layout: fixed;
}
.margin-right-8 {
  margin-right: 8px;
}
</style>
